.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.sidebar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.sidebar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

:not(.layout-fixed) .main-sidebar {
  height: inherit;
  min-height: 100%;
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
}

.pm_disabled {
  color: #d2322d;
}

.pm_enabled {
  color: #47A447;
}

.rdt_TableCol_Sortable div {
  font-size: 15px;
}

.sc-iveFHk div {
  font-size: 20px;
  margin-bottom: 15px;
}

.brand-link .brand-image {
  float: inherit;
  line-height: initial;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0px;
  max-height: initial;
  width: auto;
  box-shadow: none !important;
  height: 70px;
  border-radius: initial;
}

.dropdown-menu-lg {
  max-width: 180px;
  min-width: 180px;
  padding: 0;
}

.btn-primary {
  color: #fff;
  background-color: #ffa200;
  border-color: #ffa200;
  box-shadow: none;
}

a {
  color: #ffa200;
  text-decoration: none;
  background-color: transparent;
}